import cookieHelper from '@/helpers/cookieHelper';

// source: https://git.lf.net/ext/ERCO/incubator/product-finder/-/blob/master/app/src/services/helpers/languageHelper.ts
function getLanguage(): string {
  const lang = 'de'; // default language
  const k3Language = window.k3vars && window.k3vars.S_Sprache ? window.k3vars.S_Sprache : false;
  const cookieLanguage = cookieHelper.getCookie('erco-lang');
  const urlRegex =
    /^\/(?<langRoute>[^/]{2}(?:_[^/]{2})?)\/.*$|^\/(?<lang>([^/]{2}(?:_[^/]{2})?)$)/i;
  const urlLang = window.location.pathname.match(urlRegex);

  if (urlLang?.groups?.lang) return urlLang?.groups.lang;
  if (urlLang?.groups?.langRoute) return urlLang?.groups.langRoute;

  if (k3Language) {
    return k3Language;
  }

  if (cookieLanguage) {
    return cookieLanguage;
  }

  return lang;
}

const languageHelper = {
  getLanguage,
};

export default languageHelper;
