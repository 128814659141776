/**
 * Returns a variable from a static window object or from a given environment variable
 * @author Sven Schiffer - https://git.lf.net/ext/ERCO/incubator/product-finder
 * @param {string} name Name of the Variable
 * @return {string} Variable value
 */
export default function getEnv(name: string): string {
  if ((<any>window).configs && (<any>window).configs[name]) {
    return (<any>window).configs[name];
  }

  return process.env[name];
}
