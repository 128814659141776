import getEnv from './environmentHelper';

const IS_BUILD = getEnv('NODE_ENV') === 'production';
const IS_PROD = getEnv('VUE_APP_RUNTIME_ENV') === 'prod';

const configHelper = {
  IS_BUILD,
  IS_PROD,
  LIGHTSCOUT:
    getEnv('VUE_APP_LIGHTSCOUT') || (IS_PROD ? 'https://.erco.com' : 'http://localhost:3331'),
  PDA_API: getEnv('VUE_APP_PDA_API') || 'http://localhost:8888/api',
  PDB_API:
    getEnv('VUE_APP_PDB_API') ||
    (IS_PROD ? 'https://lightfinder.erco.com' : 'http://localhost:3330'),
  AUTH_URL: getEnv('VUE_APP_AUTH_URL'),
  DEPLOYMENT_TIMESTAMP: getEnv('VUE_APP_DEPLOYMENT_TIMESTAMP'),
};

export default configHelper;
