import { ref, onUnmounted, Ref } from 'vue';
import { HttpError } from '@/types';
import { useRouter } from 'vue-router';
import languageHelper from '../helpers/languageHelper';

type RequestOptions = {
  method?: string;
  body?: BodyInit | Record<string, unknown>;
  headers?: HeadersInit;
  useCredentials?: boolean;
  noCors?: boolean;
  redirectUnauthed?: boolean;
};

interface useFetchReturn {
  fetchApi: <T>(url: string, options: RequestOptions) => Promise<T>;
  clearError: () => void;
  hasError: Ref<boolean>;
  errorCode: Ref<number | undefined>;
  errorMessage: Ref<string | undefined>;
  isLoading: Ref<boolean>;
}

export const useFetch = (baseUrl = ''): useFetchReturn => {
  const hasError = ref(false);
  const errorMessage = ref<string>();
  const errorCode = ref<number>();
  const abortControlles: Ref<AbortController[]> = ref([]);
  const isLoading = ref(false);
  const router = useRouter();

  const clearError = (): void => {
    errorCode.value = undefined;
    errorMessage.value = '';
    hasError.value = false;
  };

  const fetchApi = async <T>(url: string, options: RequestOptions): Promise<T> => {
    isLoading.value = true;
    const {
      method = 'GET',
      body,
      headers,
      useCredentials = false,
      noCors = false,
      redirectUnauthed = true,
    } = options;
    const language = languageHelper.getLanguage();

    const requestAbortController = new AbortController();
    abortControlles.value.push(requestAbortController);

    try {
      const response = await fetch(baseUrl + url, {
        method,
        body: JSON.stringify(body),
        headers: {
          'Accept-Language': language,
          ...headers,
        },
        signal: requestAbortController.signal,
        ...(useCredentials ? { credentials: 'include' } : {}),
        ...(noCors ? { mode: 'no-cors' } : {}),
      });

      const data = response.headers.get('Content-Type')?.includes('application/json')
        ? await response.json()
        : await response.text();

      if (!response.ok) {
        if (response.status === 401 && redirectUnauthed) router.replace('/401');
        throw new HttpError(response.status, data.message);
      }

      clearError();
      return data as T;
    } catch (error) {
      hasError.value = true;
      errorMessage.value = (error as Error).message || 'unkown error';
      if (error instanceof HttpError) errorCode.value = error.code;
      throw error;
    } finally {
      isLoading.value = false;
    }
  };

  onUnmounted(() => {
    abortControlles.value.forEach((controller) => controller.abort());
  });

  return { fetchApi, clearError, hasError, errorCode, errorMessage, isLoading };
};

export default useFetch;
