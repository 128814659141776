enum Languages {
  de = 1031,
  en = 2057,
  es = 1034,
  fr = 1036,
  // eslint-disable-next-line camelcase
  en_us = 1033,
  it = 1040,
  sv = 1053,
  nl = 1043,
}

const constants = Object.freeze({
  langs: [
    { title: 'url_de', hreflang: 'de-DE', urlSlag: 'de', id: 1031 },
    { title: 'url_en', hreflang: 'x-default', urlSlag: 'en', id: 2057 },
    { title: 'url_en_gb', hreflang: 'en-GB', urlSlag: 'en_gb' },
    { title: 'url_en_us', hreflang: 'en-US', urlSlag: 'en_us', id: 1033 },
    { title: 'url_es', hreflang: 'es-ES', urlSlag: 'es', id: 1034 },
    { title: 'url_fr', hreflang: 'fr-FR', urlSlag: 'fr', id: 1036 },
    { title: 'url_it', hreflang: 'it-IT', urlSlag: 'it', id: 1040 },
    { title: 'url_nl', hreflang: 'nl-NL', urlSlag: 'nl', id: 1043 },
    { title: 'url_sv', hreflang: 'sv-SV', urlSlag: 'sv', id: 1053 },
  ],
  downloadTypes: {
    11: {
      type: 'famspecs',
      platzhalter: 'famspecs',
      platzhalter_cta: 'produktbroschuere_familie_herunterladen',
      platzhalter_ueberschrift: 'produkteigenschaften',
      complex: true,
      is_available: true,
    },
    15: {
      type: 'specs',
      platzhalter: 'specsheets',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      is_available: true,
    },
    9: {
      type: 'gbr',
      platzhalter: 'manuals',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      is_available: true,
    },
    8: {
      type: 'txt',
      platzhalter: 'texts',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      is_available: true,
    },
    16: {
      type: 'energielabel',
      platzhalter: 'energielabel',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'luminares',
      is_available: true,
    },
    10: {
      type: 'produktbilder',
      platzhalter: 'product_pictures',
      platzhalter_ueberschrift: 'bilder',
      complex: false,
      downloadable: 'all',
      is_available: true,
    },
    4: {
      type: 'ldt',
      platzhalter: 'eulumdat',
      platzhalter_ueberschrift: 'planungsdaten_lichtverteilung',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    3: {
      type: 'ies',
      platzhalter: 'ies',
      platzhalter_ueberschrift: 'planungsdaten_lichtverteilung',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    1: {
      type: '3ds',
      platzhalter: '3ds',
      platzhalter_ueberschrift: '3d_daten',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    7: {
      type: 'dxf',
      platzhalter: 'dxf',
      platzhalter_ueberschrift: '3d_daten',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    13: {
      // TODO: 13 war doppelt in Quelle, check for correct values
      type: 'uld',
      platzhalter: 'uld',
      platzhalter_ueberschrift: '3d_daten_mit_lichtverteilung',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    6: {
      type: 'max',
      platzhalter: 'max',
      platzhalter_ueberschrift: '3d_daten_mit_lichtverteilung',
      complex: false,
      downloadable: 'luminares',
      is_available: true,
    },
    100: {
      type: 'design',
      platzhalter: 'product_designpictures',
      platzhalter_ueberschrift: 'designbilder',
      complex: true,
      platzhalter_cta: '',
      is_available: true,
    },
    18: {
      type: 'gaeb_d81',
      platzhalter: 'gaeb_daten_d81',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      lang: Languages.de,
      is_available: true,
    },
    17: {
      type: 'gaeb_x81',
      platzhalter: 'gaeb_daten_x81',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      lang: Languages.de,
      is_available: true,
    },
    19: {
      type: 'revit2015',
      platzhalter: 'bim_data',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: false,
      downloadable: 'all',
      is_available: true,
    },
    20: {
      type: 'rolf',
      platzhalter: 'rolf',
      platzhalter_ueberschrift: '3d_daten_mit_lichtverteilung',
      complex: true,
      downloadable: 'luminares',
      is_available: true,
    },
    21: {
      type: 'bim_ifc',
      platzhalter: 'bim_ifc_data',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: false,
      downloadable: 'all',
      is_available: true,
    },
    22: {
      type: 'specs',
      platzhalter: 'zubehoerspecsheets',
      platzhalter_ueberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadable: 'all',
      is_available: true,
    },
    99: {
      type: 'bim_revit',
      platzhalter: 'bim_revit_data',
      platzhalterUeberschrift: 'ausschreibungsunterlagen',
      complex: false,
      downloadableFor: 'all',
      available: true,
    },
    23: {
      type: 'specsheet_planning_aid_text',
      platzhalter: 'specsheet_planning_aid_text',
      platzhalterUeberschrift: 'ausschreibungsunterlagen',
      complex: true,
      downloadableFor: 'all',
      available: true,
    },
    24: {
      type: 'epd',
      platzhalter: 'epd_download_data',
      platzhalterUeberschrift: 'ausschreibungsunterlagen',
      complex: false,
      downloadableFor: 'luminares',
      available: true,
    },
  },
  technicalRegions: [
    { id: '1', title: '220V-240V/50Hz' },
    { id: '2', title: '120V/60Hz' },
  ],
  technicalRegionsObject: {
    1: '220V-240V/50Hz',
    2: '120V/60Hz',
  },
  knownErrors: ['auth_token_expired', 'auth_token_missing', 'auth_token_invalid'],
});

export default constants;
