import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { KubernetesVars } from '@/types';
import App from './App.vue';
import router from './router';
import languageHelper from './helpers/languageHelper';
import '@/styles/main.scss';
import getEnv from './helpers/environmentHelper';
import configHelper from './helpers/configHelper';

const k3vars: KubernetesVars = {};
k3vars.S_Sprache = '';
k3vars.Bereich = '[js_S_Bereich]';
k3vars.PKM_Bereich = '1';
k3vars.Spezifikation = 'produkte_indices';
k3vars.PKM_Spezifikation = '51';
k3vars.Gattung = '';
k3vars.PKM_Gattung = '267';
k3vars.Objekt = 'Product Finder';
k3vars.S_Objekt = 'Product Finder';
k3vars.S_Gattung = '[js_S_Gattung]';
k3vars.PKM_Objekt = '7093';
k3vars.Thema = '';
k3vars.PKM_Thema = '403';
k3vars.PKM_Seite = '63323';
k3vars.S_Template = 'flat_header.htm';
k3vars.FKM_PKM_PDB = '0';
k3vars.multilang_urls = {
  de: '/de',
  en: '/en',
  'x-default': '/en',
};

window.k3vars = k3vars;
window.onload_functions = [];
window.globals = {};
window.artikelfilter = {};

const initialLanguage = languageHelper.getLanguage();

window.k3vars.S_Sprache = initialLanguage;

const timestamp = configHelper.DEPLOYMENT_TIMESTAMP;

let naviagtionSnippet: string | undefined;

let navigationResolve: (value: unknown) => void;
const navigationInsertPromise = new Promise((resolve) => {
  navigationResolve = resolve;
});

window.insertNavigation = () => {
  if (!naviagtionSnippet) {
    console.error('No navigation snippet found');
    return;
  }

  const navigation = document.createRange().createContextualFragment(naviagtionSnippet);
  const header = document.querySelector('header');
  header?.replaceWith(navigation);
  navigationResolve(true);
};

fetch(
  `${getEnv(
    'VUE_APP_LIGHTSCOUT',
  )}/api/snippet-server/?lang=${initialLanguage}&snippets=navigation,js,footer,css,cssv3,meta,lssearch${
    timestamp ? `&timestamp=${timestamp}` : ''
  }`,
)
  .then((response) => {
    // TODO: add some kind of fallback / error handling?
    return response.json();
  })
  .then((snippets) => {
    const head = document.getElementsByTagName('head')[0];
    const body = document.getElementsByTagName('body')[0];

    naviagtionSnippet = snippets.navigation;

    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', snippets.cssv3);
    link.setAttribute('onload', 'insertNavigation()');
    head.appendChild(link);

    // @TODO: Does this work?
    const css = document.createElement('link');
    css.setAttribute('rel', 'stylesheet');
    css.setAttribute('type', 'text/css');
    css.setAttribute('href', snippets.css);
    head.appendChild(css);

    const footer = document.createRange().createContextualFragment(snippets.footer);
    body.appendChild(footer);

    const meta = document.createElement('template');
    meta.innerHTML = snippets.meta;
    head.append(meta.content);

    const lssearch = document.createElement('script');
    lssearch.innerText = `const fullTextSearchPath = '${snippets.lssearch}';`;
    head.appendChild(lssearch);

    return navigationInsertPromise.then(
      () =>
        new Promise((resolve) => {
          const js = document.createElement('script');
          js.setAttribute('type', 'text/javascript');
          js.setAttribute('src', snippets.js);
          js.setAttribute('async', '');
          js.onload = resolve;
          head.appendChild(js);
        }),
    );
  })
  .finally(() => {
    const i18n = createI18n({
      locale: initialLanguage,
      fallbackLocale: 'en',
      messages: {
        de: {
          title: 'Planungsdatenarchiv',
          downloadTyp: 'Downloadtyp',
          filename: 'Dateiname',
          'search...': 'Suchen...',
          resetSearch: 'Formular zurücksetzen',
          submitSearch: 'Suchen',
          year: 'Jahr',
          search: 'Suchbegriff/Artikelnummer',
          language: 'Sprache',
          productType: 'Produktart',
          article: 'Artikel',
          accessory: 'Zubehörteil',
          dropdownAll: 'Alle',
          technicalRegion: 'Technische Region',
          productImage: 'Produktbild',
          lang_1031: 'Deutsch',
          lang_2057: 'Englisch',
          lang_1034: 'Spanisch',
          lang_1036: 'Französisch',
          lang_1033: 'Englisch (US)',
          lang_1040: 'Italienisch',
          lang_1053: 'Schwedisch',
          lang_1043: 'Niederländisch',
          nextPage: 'Nächste Seite',
          prevPage: 'Vorherige Seite',
          xml_data: 'XML Daten',
          zubehoerspecsheets: 'Zubehöreigenschaften',
          region: 'Region',
          downloadFile: 'Herunterladen',
          createShareLink: 'Teilbaren Link erstellen',
          sharableLinkHint:
            'Dieser temporäre Link ist für 7 Tage gültig. Sobald er einmal genutzt wurde ist er nur noch weitere 15 Minuten gültig.',
          copySharableLink: 'Link kopieren',
          closeModal: 'Schließen',
          copySuccess: 'Link kopiert',
          genericError: 'Etwas ist schiefgelaufen ',
          noSearchResult: 'Für diese Suche konnten keine Ergebnisse gefunden werden.',
          minCharacterLengthSearch: 'Bitte geben Sie mindestens 3 Zeichen ein',
          pageLoadError: 'beim Laden der nächsten Seite',
          yearLoadError: 'beim Laden der verfügbaren Jahre',
          searchLoadError: 'beim Durchführen der Suche',
          createSharableLinkError: 'beim Erstellen des Links',
          loadingDownloadsError: 'beim Laden der Downloads',
          loadingLanguageError: 'beim Laden der Übersetzungen',
          successfulDownloadLinkHeader: 'Downloadlink wurde erfolgreich erstellt',
          authFailed_heading: 'Authentifizierung fehlgeschlagen',
          authFailed_prompt:
            'Bitte melden Sie sich bei myErco an oder versuchen Sie es später erneut.',
          login: 'Anmelden',
          reloadPage: 'Neu laden',
          checkingAuth: 'Einen Moment Geduld bitte, Ihr Login wird verifiziert',
          authFailed_staffPrompt:
            'Um auf das Planungdaternarchiv zugreifen zu können, müssen Sie mit einem Mitarbeiter Account eingeloggt sein.',
          auth_token_expired: 'Der angefragte Link ist abgelaufen oder ungültig',
          auth_token_missing:
            'Um auf die Datei zugreifen zu können, müssen Sie mit einem Mitarbeiter Account eingeloggt sein oder einen gültigen Link haben.',
          auth_token_invalid: 'Der angefragte Link darf nicht auf diese Datei zugreifen.',
          yearHint:
            'Planungsdaten vor 2018 sind im ERCO Netzwerk über das FileSystem-Archiv aufrufbar.',
          unkownDownloadType: 'Unbekannter Downloadtyp ({type})',
        },
        en: {
          title: 'Planning data archive',
          downloadTyp: 'Download type',
          filename: 'Filename',
          'search...': 'search...',
          resetSearch: 'Reset',
          submitSearch: 'Search',
          year: 'Year',
          search: 'Search/Articlenumber',
          language: 'Language',
          productType: 'Product type',
          article: 'Article',
          accessory: 'Accessory',
          dropdownAll: 'All',
          technicalRegion: 'Technical region',
          productImage: 'Product image',
          lang_1031: 'German',
          lang_2057: 'English',
          lang_1034: 'Spanish',
          lang_1036: 'French',
          lang_1033: 'English (US)',
          lang_1040: 'Italian',
          lang_1053: 'Swedish',
          lang_1043: 'Dutch',
          nextPage: 'Next page',
          prevPage: 'Previous page',
          xml_data: 'XML Data',
          zubehoerspecsheets: 'Accessory specification sheets',
          region: 'Region',
          downloadFile: 'Download',
          createShareLink: 'Generate sharable link',
          sharableLinkHint:
            'This temporary public link will be available for 7 days. After someone used it, it will be valid for 15 mins.',
          copySharableLink: 'Copy link',
          closeModal: 'Close',
          copySuccess: 'Link successfully copied',
          genericError: 'Something went wrong ',
          noSearchResult: "Couldn't find any matches for this search.",
          minCharacterLengthSearch: 'Please enter at least 3 characters',
          pageLoadError: 'loading the next page',
          yearLoadError: 'loading available years',
          searchLoadError: 'while searching',
          createSharableLinkError: 'creating the link',
          loadingDownloadsError: 'loading the downloads',
          loadingLanguageError: 'loading the translations',
          successfulDownloadLinkHeader: 'Successfully created download link',
          authFailed_heading: 'Authentication failed',
          authFailed_prompt: 'Please log in to myErco or try again later.',
          login: 'Login',
          reloadPage: 'Reload',
          checkingAuth: 'One moment please, your login is being verified',
          authFailed_staffPrompt:
            'In order to access the planning data archive, you must be logged in with a staff account.',
          auth_token_expired: 'The requested link has expired or is invalid',
          auth_token_missing:
            'To access the file, you must be logged in with a staff account or have a valid link.',
          auth_token_invalid: 'The requested link is not allowed to access this file.',
          yearHint:
            'Planning data before 2018 can be accessed in the ERCO network via the file system archive.',
          unkownDownloadType: 'Unknown download type ({type})',
        },
      },
      missing: (locale, key, vm, values) => {
        // We are lazyloading the translations, so it will be missing for sure.
        // Due to avoid showing placeholders in production, we return emptry strings.
        if (getEnv('NODE_ENV') === 'production') {
          return '';
        }

        return key;
      },
    });

    createApp(App).use(router).use(i18n).mount('#app');
  });
