import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SearchView from '@/views/SearchView.vue';
import AuthErrorView from '@/views/AuthErrorView.vue';
import getEnv from '@/helpers/environmentHelper';
import constants from '../config/constants';
import languageHelper from '../helpers/languageHelper';
import { useAuthentication } from '../hooks';

const { requireAuth } = useAuthentication();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/search',
  },
  {
    path: '/search',
    name: 'searchView',
    component: SearchView,
    beforeEnter: requireAuth,
  },
  {
    path: '/401',
    component: AuthErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(`${getEnv('BASE_URL')}${languageHelper.getLanguage()}`),
  routes,
});

export default router;
