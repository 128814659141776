import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { Notification } from '../types';

// Outside of hook so it is 'global'
const notifications = ref<(Notification & { timeout: number })[]>([]);

export const useNotifications = () => {
  /**
   * Removes a existing notification from the stack
   * @param {string} id - id of the notification
   */
  const removeNotification = (id: string) => {
    notifications.value = notifications.value.filter((item) => item.id !== id);
  };

  /**
   * Display a new notification
   * @param content - Content to display
   * @param type - Type of notifications, determines it's color
   * @param duration - Duration after that the notification will be automatically removed (in s)
   */
  const showNotification = (
    content: string,
    type: Notification['type'] = 'default',
    duration = 7,
  ) => {
    const id = uuidv4();
    const timeout = setTimeout(() => {
      removeNotification(id);
    }, duration * 1000);

    notifications.value.push({ id, content, type, duration, timeout });
  };

  /**
   * Clear a specific notifications timeout to prevent it from disappearing while a user interacts with it
   * @param {string} id - id of the notification
   */
  const clearNotificationTimer = (id: string) => {
    const notification = notifications.value.find((item) => item.id === id);
    if (!notification) return;

    clearTimeout(notification.timeout);
  };

  /**
   * Create a new Timeout when a user is finished interacting with a notification
   * @param {string} id - id of the notification
   */
  const restartNotificationTimer = (id: string) => {
    const notificationIndex = notifications.value.findIndex((item) => item.id === id);
    if (notificationIndex === -1) return;

    const { duration } = notifications.value[notificationIndex];

    notifications.value[notificationIndex].timeout = setTimeout(() => {
      removeNotification(id);
    }, duration * 1000);
  };

  return {
    showNotification,
    removeNotification,
    notifications,
    clearNotificationTimer,
    restartNotificationTimer,
  };
};

export default useNotifications;
